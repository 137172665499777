//custom colors for CHAMP PORTAL

// Import CoreUI default colors
@import "../../node_modules/@coreui/coreui-pro/scss/variables/colors";

// Customized Bootstrap variables
@import "../../node_modules/@coreui/coreui-pro/scss/variables/bootstrap/variables";
@import "../../node_modules/@coreui/coreui-pro/scss/variables/colors";


//champ color palette
$callOut:   #FFFFCC;
$blue:       #0000ff;
$red:        #ff0000;
$orange:     #ffc000;
$green:      #00b050;
$white:      #ffffff;
$gray:       #465564;
$lightYellow:#FFFFC0;
$lightBlue:  #4da3ff;
$primary:       #16CA03;
$secondary:     #417FF2;
$tertiary:      #495B73;
$black: 	#000;

$success:       $green;
//$info:          $light-blue !default;
$warning:       $orange;
$warning-bg: #fff3cd;
$danger:        #D51E00;
$danger-bg: rgba(248, 108, 107, 0.3);

$arrow-pending: rgba(255, 217, 0, 0.776);
$arrow-complete: rgba($primary, 0.8);
$arrow-danger: rgba($danger, 0.8);
$arrow-preview: rgba(255, 217, 0, 0.4);
$arrow-disabled: rgba($gray-400, 0.8);
//$light:         $gray-100 !default;
//$dark:          $gray-800 !default;
$gray-base: #465564;
$gray-400:  #96A0AA;
$gray-600:	#C8CDD2;
$gray-800:  #f0f0f0;
$gray-pdf-viewer: #5C6164;
$gray-pdf-viewer-shadow: #4E5255;


$body-bg:                            $gray-800;
$footer-bg:                          $white;

//$sidebar-bg:                          $gray-100;
$sidebar-bg:                          #495B73;
$sidebar-color:                       $white;
$sidebar-width:                       250px;


$sidebar-nav-link-hover-color:        #fff;
$sidebar-nav-link-hover-bg:           $gray-400;
$sidebar-nav-link-hover-icon-color:   #fff;
$sidebar-nav-link-hover-borders:      0;
$sidebar-nav-link-icon-color:         #ffffff;

$sidebar-nav-link-active-color:       #000000;
$sidebar-nav-link-active-bg:          #f0f0f0;
$sidebar-nav-link-active-icon-color:  $gray-400;
$sidebar-nav-link-active-borders:     0;
$navbar-height:                       60px;

//breadcrumb
$breadcrumb-borders: (
  bottom: (
    size:                             0px,
    )
);

$breadcrumb-padding-y:              .75rem;
$breadcrumb-padding-x:              2rem;
$breadcrumb-item-padding:           .25rem;
$breadcrumb-margin-bottom:          .5rem;
$breadcrumb-bg:                     $gray-800;
$breadcrumb-divider-color:          $gray;
$breadcrumb-active-color:           $gray;


$navbar-border: (
  bottom: (
    size:                             1px,
    style:                            solid,
    color:                            $gray-400
  )
);

//header icon hover
$navbar-hover-color:                $primary;

//side nav
$sidebar-minimizer-bg:              $gray-400;
$sidebar-minimizer-hover-bg:        $blue;

//avatar
$avatar-width:                        24px ;

//$purple:     #6f42c1 !default;
//$pink:       #e83e8c !default;

//$yellow:     #ffc107 !default;

//$teal:       #20c997 !default;
//$cyan:       #17a2b8 !default;
//$light-blue: #63c2de !default;

//dropdown-header
$dropdown-divider-bg:               $gray-800;
$dropdown-icon-color:               $primary;

$theme-colors: (
	primary: $primary
);

$coc-portal-header-bg: #182A49;
$coc-portal-border-color: #3C4B59;
$coc-portal-doc-header-bg: #EFF2F4;
$coc-portal-table-bg: #F2F6F8;

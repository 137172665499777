@import '../../../scss/variables';

.chips-container {
	display: flex;
    flex-wrap: wrap;
    justify-content: left;


	.chip-container {
		border-radius: 30px;
		display: inline-flex;
		justify-content: center;
		margin: 3px;
		align-items: center;
		border-width: 1px;
		border-style: solid;
		border-color: $primary;
		background-color: $primary;

		&.static {
			color: $black;
			border-color: $gray-400;
			background-color: $gray-800;
		}


		&.party-pack {
			border-radius: 5px;
		}

		.chip-label {
			color: inherit;
			font-weight: bold;
			padding: 0 12px;
			white-space: nowrap;

			&.small-label {
				font-size: 8px;
			}

			&.big-label {
				font-size: 12px;
			}

			&.side-label {
				border: 1px solid #4c5c68;
				padding: 0;
				transform: rotate(-90deg);
			}

		}

		.chip-action {
			z-index: 1;
			cursor: pointer;
			width: 20px;
			height: 20px;
			margin: 1px 3px 0 -5px;
			display: inline-block;
			font-size: 18px;
			color: #ffffff;
		}
	}

	.chip-with-side-container {
		color: #ffffff;
		border: 1px solid #00b0f0;
		border-left: 20px solid #00b0f0;
		background-color: #0000ff;
		border-radius: 30px;
		display: inline-flex;
		justify-content: center;
		margin: 3px;
		align-items: center;
		position: relative;
		min-height: 115px;

		&.party-pack {
			border-radius: 5px;
		}

		&.static {
			color: $black;
			border-color: $gray-400;
			background-color: $gray-800;
		}

		.side-label {
			color: #ffffff;
			font-weight: bold;
			transform: rotate(-90deg);
		    transform-origin: 0 0;
		    position: absolute;
		    bottom: 0;
		    left: 0;
		    margin-left: -17px;
		    margin-bottom: -7px;
		    white-space: nowrap;
		    font-size: 10px;
		}

		.chip-label {
			color: inherit;
			font-weight: bold;
			padding: 0 12px;
			white-space: nowrap;

			&.small-label {
				font-size: 8px;
			}

			&.big-label {
				font-size: 12px;
			}
		}

		.chip-action {
			z-index: 1;
			cursor: pointer;
			width: 20px;
			height: 20px;
			margin: 1px 3px 0 -5px;
			display: inline-block;
			font-size: 18px;
			color: $white;
		}

	}
}

:export {
	primary: $primary;
	secondary: $secondary;
	danger: $danger;
	warning: $warning;
	success: $success;
	gray400: $gray-400;
	gray800: $gray-800;
	gray: $gray;
	white: $white;
	red: $red;
	black: $black;
	blue: $blue;
	light: $light;
	dark: $dark;
	lightBlue: $lightBlue;
}
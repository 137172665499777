.radios-grouped {
	font-size: 10px;
}

.radio-btn-group {
	display: inline-block;
	justify-content: center;
}

.radiobtn {
	cursor: pointer;
	display: inline-block;
	border: 1px solid #ccc;
	border-radius: 20px;
	text-align: center;
	font-weight: bold;
	max-width: 30rem;
	padding: 5px;
	margin: 3px;
}

.disabled {
	cursor: default;
}

.radiobtn.checked {
	color: #fff;
    border-color: #20a8d8;
    background-color: #20a8d8;
}

.radiobtn.checked.disabled {
	color: #eee;
	background-color: #8fbecf;
}

.radiobtn.unchecked {
	background-color: #fff;
}

.radiobtn.unchecked.disabled {
	color: #aaa;
	background-color: #eee;
}

.radios-grouped.is-invalid {
	background-color: rgba(248, 108, 107, 0.3);
	border: 1px solid #f86c6b;
}

.radios-grouped.is-invalid .radiobtn {
	border-color: #f86c6b;
}
html, body {
    height: 100%;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
    font-size: 16px !important;
}

.pointer {
    cursor: pointer;
}

.no-wrap {
    white-space: nowrap;
}

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0,0,0,0.5);
    box-shadow: 0 0 1px rgba(255,255,255,0.5);
}


@font-face {
    font-family: 'Beth Ellen';
    src: url(./fonts/BethEllen-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Dancing Script';
    src: url(./fonts/DancingScript-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Great Vibes';
    src: url(./fonts/GreatVibes-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Homemade Apple';
    src: url(./fonts/HomemadeApple-Regular.ttf) format('truetype');
}

@font-face {
    font-family: Kristi;
    src: url(./fonts/Kristi-Regular.ttf) format('truetype');
}

@font-face {
    font-family: Parisienne;
    src: url(./fonts/Parisienne-Regular.ttf) format('truetype');
}